import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";

// Import your extended theme from a location in `./src`
import customTheme from "./src/theme";

export const wrapRootElement = ({ element }) => (
  // Or ChakraBaseProvider if you only want to compile the default Chakra theme tokens
  <ChakraProvider theme={customTheme}>{element}</ChakraProvider>
);
